import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import Layout from "../../components/Layout/Layout";
import { Device_Api_Url } from "../../URL/url";
import axios from "axios";


function Page2(props) {
  const [userDeviceHistory, setUserDeviceHistory] = useState([])
  useEffect(()=>{
    const userId = localStorage.getItem('userId')
      axios.get(Device_Api_Url+'device/deviceList/'+ userId).then((resp)=>{
          console.log(resp.data, "Get particular Devices")
          const mapData = resp.data.map(items=>{
              return {
                Device_Name: items.deviceName,
                Device_IP: items.ipAddress,
                Device_Details: items.deviceDescription,
                Device_Logins: items.numberOfLogin,
                Device_history: "NUll"
              }
           })
           console.log(mapData,"map")
           setUserDeviceHistory(mapData)
      })
   
  },[])
  const columns = [
    {
      name: "Device_Name",
      label: "Device Name",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "Device_Details",
      label: "Device Details",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "Device_IP",
      label: "Device IP",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "Device_History",
      label: "Device History",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "Device_Location",
      label: "Device Location",
      options: {
        filter: true,
        sort: false,
      }
    },

  ];
  const data = [
    { Device_Name: "Joe James", Device_Details: "Test Corp", Device_IP: "Yonkers", Device_History: "NY", Device_Location: "NY" },
    { Device_Name: "John Walsh", Device_Details: "Test Corp", Device_IP: "Hartford", Device_History: "CT", Device_Location: "NY" },
    { Device_Name: "Bob Herm", Device_Details: "Test Corp", Device_IP: "Tampa", Device_History: "FL", Device_Location: "NY" },
    { Device_Name: "James Houston", Device_Details: "Test Corp", Device_IP: "Dallas", Device_History: "TX", Device_Location: "NY" },
  ];

  const options = {
    filterType: 'checkbox',
  }
  return (
    <Layout role="user">

      <MUIDataTable
        title={"History"}
        data={userDeviceHistory}
        columns={columns}
        options={options}
      />
    </Layout>
  );
}

export default Page2;
