import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import OtpInput from "react-otp-input";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { User_Api_Url } from "../../../URL/url";
import Spinner from "react-spinner-material";
import axios from "axios";
import "./OtpModal.css";
import ClearIcon from "@mui/icons-material/Clear";

const style = {
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function OtpModal(props) {
  let navigate = useNavigate();
  const [spinnerCheck, setSpinnerCheck] = useState(false);
  const [counter, setCounter] = useState(3);
  const [otpVal, setOtpVal] = useState("");
  const [failed, setFailed] = useState(false);
  const [codeAgain, setCodeAgain] = useState(false);
  const [time, setTime] = useState(15);
  const [sendEmail, setSendEmail] = useState("");
  const [myCheck,setMyCheck]=useState(0);

  const newFunction=()=>{
    const timer = setTimeout(() => {
      setTime(time - 1);
    }, 1000);
    const myinterval=setInterval(timer, 1000);
    if (time === 0) {
      clearTimeout(timer);
      setTime(15);
      // console.log("okay");
      setCodeAgain(true);
    clearInterval(myinterval);
    setMyCheck(false);
    }
  }

  useEffect(() => {
    setSendEmail(localStorage.getItem("localEmail"));
    setMyCheck(props.otpModalOpen);
  }, [props.otpModalOpen])
  

  if(myCheck){
    newFunction();
  }
  
  useEffect(() => {
    setCodeAgain(false);
  }, []);

  const handleOtpVal = (otp) => {
    setOtpVal(otp);
  };
  if (failed) {
    const countInterval = setInterval(() => {
      setCounter(counter - 1);
    }, 1000);
    if (counter === 0) {
      setFailed(false);
      props.handleOtpModalClose();
      clearInterval(countInterval);
      // console.log("hello");
    }
  }

  const fetchVerifyEmail = async (otpVal) => {
    setTime(15);
    await axios
      .post(`${User_Api_Url}/verify-email`, { otp: +otpVal })
      .then((resp) => {
        // console.log(resp.data.code, resp.data, "code")
        if (resp.data.code=== 200) {
          console.log(props.inputValue,"here is my input value in otp");
          navigate("/subscription",{state:{email:props?.inputValue.email
            ,password:props?.inputValue.password,
            mobile:props?.inputValue.mobile,
            desktop:props?.inputValue.desktop,
            tab:props?.inputValue.tab
          }});
        } else {  
          setSpinnerCheck(false);
          setFailed(true);
        }
        // console.log("resp", resp);
      }).catch(()=>{
        setSpinnerCheck(false);
        setFailed(true);
      })
  }

  const handleVerify = () => {
    // console.log(otpVal, "otpVal");
    if(otpVal.length<4)
    setFailed(true);
    else{
      setSpinnerCheck(true);
      fetchVerifyEmail(otpVal);
    }

  };
  const handleSendAgain = () => {
    setTime(15);
    setCodeAgain(false);
    setMyCheck(true);
    // console.log(props, "props");
     axios.post(`${User_Api_Url}/signup`, props.inputValue ).then(resp=>{
      if(resp){
        console.log(resp,"my res");
        setCodeAgain(true);
      }
     })
  };

  return (
    <Modal
      open={props.otpModalOpen}
      onClose={props.handleOtpModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {spinnerCheck ? (
          <div className="spinnerDiv">
            <Spinner radius={120} color={"#333"} stroke={2} visible={true} />
            <br />
            <h4>Verifying please wait ......</h4>
          </div>
        ) : failed ? (
          <div className="failedDiv">
            <div className="failedImageDiv">
              <ClearIcon
                style={{
                  width: "80px",
                  height: "80px",
                  color: "white",
                }}
              />
            </div>
            <div className="failedHeading">Verification Failed !!!</div>
            <div className="redirectingPara">
              Redirecting... to Signup in {counter} seconds
            </div>
          </div>
        ) : (
          <div className="modalDiv">
            <div className="modalHeading">Enter Verification Code</div>
            <div className="modalPara">
              We have just sent a verification code to {sendEmail}
            </div>
            <div className="codeSection">
              <OtpInput
                value={otpVal}
                onChange={handleOtpVal}
                numInputs={4}
                separator={<span> - </span>}
                onPaste={(e)=>{
   e. preventDefault()
   return false;
   }}
              />
            </div>
            <div>
              {codeAgain ? (
                <div onClick={handleSendAgain} className="sendAgainBtn">
                  Send the code again
                </div>
              ) : (
                <div style={{ color: "red" }}>
                  {time < 10 ? `00:0${time}` : `00:${time}`}
                </div>
              )}
            </div>
            <div className="modalBtns">
              <div className="cancel" onClick={props.handleOtpModalClose}>
                Cancel
              </div>
              <div
                className="verify"
                onClick={handleVerify}
              >
                Verify
              </div>
            </div>
          </div>
        )}
      </Box>
    </Modal>
  );
}
