import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import { Device_Api_Url } from "../../URL/url";


function Page1(props) {
  const [userDeviceList, setUserDeviceList] = useState([])
  useEffect(()=>{
    const userId = localStorage.getItem('userId')
      axios.get(Device_Api_Url+'device/deviceList/'+ userId).then((resp)=>{
          // console.log(resp.data, "Get particular Devices")
          const mapData = resp.data.map(items=>{
        let myIndex = items.deviceDescription.indexOf("on");
        let myDevice;
        if(items.isMobile){
          myDevice="Mobile";
        }
        else if(items.isDesktop){
          myDevice="Desktop";
        }
        else if(items.isIpad){
          myDevice="Ipad";
        }
              return {
                Device_Name: myDevice,
                Device_IP: items.ipAddress,
                Device_Details: items.deviceDescription.slice(myIndex + 2),
                Device_Logins: items.numberOfLogin 
              }
           })
           console.log(mapData,"map")
           setUserDeviceList(mapData)
      })
   
  },[])
  const columns = [
    {
      name: "Device_Name",
      label: "Device Name",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "Device_Details",
      label: "Device Details",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "Device_IP",
      label: "Device Ip",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "Device_Logins",
      label: "Device Logins",
      options: {
        filter: true,
        sort: false,
      }
    },

  ];
  const data = [
    { Device_Name: "Joe James", Device_Details: "Test Corp", Device_IP: "Yonkers", Device_Logins: "NY" },
    { Device_Name: "John Walsh", Device_Details: "Test Corp", Device_IP: "Hartford", Device_Logins: "CT" },
    { Device_Name: "Bob Herm", Device_Details: "Test Corp", Device_IP: "Tampa", Device_Logins: "FL" },
    { Device_Name: "James Houston", Device_Details: "Test Corp", Device_IP: "Dallas", Device_Logins: "TX" },
  ];

  const options = {
    filterType: 'checkbox',
  }
  return (
    <Layout role="user">

      <MUIDataTable
        title={"Devices"}
        data={userDeviceList}
        columns={columns}
        options={options}
      />
    </Layout>
  );
}

export default Page1;
