import "./Login.styles.css";
import { ToastContainer, toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { User_Api_Url } from "../../URL/url";
import axios from "axios";
import swal from 'sweetalert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {isMobile,isTablet,isDesktop } from 'react-device-detect';
import { LensTwoTone } from "@mui/icons-material";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Login = () => {
  const [open, setOpen] = useState(false);
  const [ limitMessage, setLimitMessage] = useState("");
  const [myUserId,setMyUserId] = useState("");
  const [myCheck,setMyCheck]=useState(false);
  let [inputValue, setInputValue] = useState({
    email: "",
    password: "",
    mobile:false,
    tab:false,
    desktop:false
  });
  let navigate = useNavigate();

  // let [inputValue, setInputValue] = useState({
  //   email: "",
  //   password: "",
  // });
  

  const [rememberCheck,setRememberCheck]=useState(false);

  const datafield = (e) => {
    const { name, value } = e.target;
    console.log(e.target.value);

    setInputValue({ ...inputValue, [name]: value });
  };

  useEffect(() => {
    console.log(myCheck,"here is my check");
    if(myCheck)
    {
    console.log("incheck");
      axios.post(`${User_Api_Url}/signin`,inputValue).then(resp=>{
        console.log(inputValue,"my input in post");
        console.log(resp,"my response");
  
            // console.log(resp,"email check ");
            if(resp?.status===201){
              setMyUserId(resp.data?.userId);
              setLimitMessage(resp.data.message);  
              toast.warn(`${resp.data.message}`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setOpen(true);
              setMyCheck(false);
            }
            if(resp.data?.data?.isEmailVerified){
              setMyCheck(false);
              localStorage.setItem('token',resp?.data?.token);
              localStorage.setItem('userId',resp.data?.data._id)
              toast.success("Login Succesfully", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              localStorage.setItem('isLoggedIn',resp.data?.data?.isLoggedIn)
              if(resp.data?.data?.role.role === "admin"){
                navigate('/admin/User_list');
              }else{
                navigate('/user/devices');
              }
            }
            
        //     else if(resp.data.code==="400"){
        // console.log(resp?.data,"myresponse");
        // setMyCheck(false);
        // swal({
        //   title: "User Does Not Exist",
        //   text: "Please Relogin",
        //   icon: "error",
        //   dangerMode: true,
        // })
        //     }
            
          })
        .catch(()=>{
        setMyCheck(false);   
        swal({
          title: "Either You Are Blocked Or User Does Not Exist",
          text: "Please Relogin",
          icon: "error",
          dangerMode: true,
        })
        }) 
    }
     }, [myCheck])
  

  useEffect(()=>{
    function getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }
    setInputValue({
      email:getCookie("loginEmail"),
      password:getCookie("loginPassword")
    })
  },[]);

    const handleCheck=(e)=>{
      if(e.target.checked)
      setRememberCheck(true);
      else
      setRememberCheck(false);
      }

      const handleGetStarted=()=>{
        navigate("/");
      }

  const handleForceLogout = async ()=>{
    axios.put(`${User_Api_Url}forced-signout/${myUserId}`).then(resp=>{
//  console.log("Force logout", resp.data.message);
 toast.success("All User devices logged out successfully", {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});
setOpen(false);
setMyCheck(false);

    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !inputValue.email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {  
      toast.error("Email is not correct", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setMyCheck(false);
    } else {
      if(isMobile){
        setInputValue({...inputValue,["mobile"]:true})
      }
      else if(isTablet){
    
        setInputValue({...inputValue,["tab"]:true});
    
      }
      else if(isDesktop){
    
        setInputValue({...inputValue,["desktop"]:true});
    
      }

      setMyCheck(true);
    }
    //   axios.post(`${User_Api_Url}/signin`,inputValue).then(resp=>{
    // console.log(inputValue,"my input in post");

    //     // console.log(resp,"email check ");
    //     if(resp.data?.message){
    //       setMyUserId(resp.data?.userId);
    //       setLimitMessage(resp.data.message)  
    //       toast.warn(`${resp.data.message}`, {
    //         position: "top-right",
    //         autoClose: 3000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //       });
    //       setOpen(true)
    //     }
    //     if(resp.data?.data?.isEmailVerified){
    //       localStorage.setItem('userId',resp.data?.data._id)
    //       toast.success("Login Succesfully", {
    //         position: "top-right",
    //         autoClose: 1000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //       });
    //       localStorage.setItem('isLoggedIn',resp.data?.data?.isLoggedIn)
    //       if(resp.data?.data?.role.role === "admin"){
    //         navigate('/admin/User_list');
    //       }else{
    //         navigate('/user/devices');
    //       }
    //     }
    //     else if(resp.data.code==="201")
    //     swal({
    //       title: "User Does Not Exist",
    //       text: "Please Relogin",
    //       icon: "error",
    //       dangerMode: true,
    //     })
    //   })
    // console.log(rememberCheck,inputValue.email,inputValue.password,"mycreds");
    if(rememberCheck===true){
      document.cookie=`loginEmail=${inputValue.email} ;path=/login`;
      document.cookie=`loginPassword=${inputValue.password} ;path=/login`;
    }
   
  };
  return (
    <div>
        <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="heading">
            <div className="icon"> <WarningAmberIcon stroke="red"  fontSize="large"/></div>
            <div className="title">{limitMessage}</div>
          </div>
          <div className="btn">
          <Button  onClick={()=>handleForceLogout()}>Force Logout</Button>
          </div>
        </Box>
      </Modal>
      {/*  <WarningAmberIcon stroke="red"/> {limitMessage} */}
      <div className="imageContainer">
        <img
          src="https://icdn.video/sites/all/themes/ctv_theme/logo.png"
          alt=""
        />
      </div>
      <form onSubmit={handleSubmit} className="formContainer">
        <div className="formHeading">
          <h3>Sign into your account</h3>
        </div>
        <ToastContainer />
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Email address</label>
          <input
            type="email"
            className="form-control"
            onChange={datafield}
            name="email"
            id="exampleInputEmail1"
            placeholder="Enter email"
            value={inputValue.email}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputpassword">Password</label>
          <input
            type="password"
            className="form-control"
            onChange={datafield}
            name="password"
            id="exampleInputpassword"
            placeholder="Enter password"
            value={inputValue.password}
            required
          />
        </div>
        <div className="formCheckBox">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="customCheck1"
            />
              <label className="" htmlFor="customCheck1">
          <input type="checkbox" onChange={handleCheck} id="customCheck1" style={{ marginTop:"1rem",
            marginRight:"0.5rem"}}/>
            Remember me
          </label>
          </div>
        </div>
        <div
          className="g-recaptcha"
          data-sitekey="6Lel4Z4UAAAAAOa8LO1Q9mqKRUiMYl_00o5mXJrR"
        ></div>
        <div className="formLoginContainer">
          {/* <Link to="/user/devices"> */}
            <button
              type="submit"
              value="Submit"
              className="btn btn-primary formLoginButton"
            >
              LOG IN
            </button>
          {/* </Link> */}
        </div>
        <div className="formForgetPassword">
          <h6>Forgot password?</h6>
        </div>
        <div className="formStillNoAccount">
          <span>
            <h6>Still no account?</h6>
          </span>
          {/* <Link to="/"> */}
            <button
              className="btn formStillNoAccountButton"
              onClick={handleGetStarted}
            >
              Get Started
            </button>
          {/* </Link> */}
        </div>
      </form>
    </div>
  );
};

export default Login;
