export const dataModal=[
    {
        plan:"Basic",
        amount:600,
        devices:2,
        mobile:2,
        ipad:1,
        desktop:1,
    },
    {
        plan:"Extended",
        amount:800,
        devices:3,
        mobile:2,
        ipad:2,
        desktop:2,
    },
    {
        plan:"Premium",
        amount:1000,
        devices:4,
        mobile:4,
        ipad:3,
        desktop:2,
    }
]