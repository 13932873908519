import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { dataModal } from "./DataModal"
import { useState } from "react";
import "./AddSubsModal.css";
import axios from "axios";
import { TextField } from "@mui/material";
import { Subscription_Api_Url } from "../../../URL/url";
import CloseIcon from '@mui/icons-material/Close';

const style = {
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function AddSubsModal(props) {

  const [request, setRequest] = useState({
    subscriptionPlan: "",
    amount: "",
    // devicesAllowed: "",
    mobile:"",
    ipad:"",
    desktop:""
  })

  // const [amount,setAmount]=useState("");
  // const [devices,setDevices]=useState("");

  const handleOption = (e) => {
    const indexOfDataModal = e.target.value[e.target.value.length - 1];
    console.log(dataModal[indexOfDataModal],"here is my obj");
    setRequest({ ...request, amount: dataModal[indexOfDataModal].amount
      // , devicesAllowed: dataModal[indexOfDataModal].devices
      , subscriptionPlan: dataModal[indexOfDataModal].plan 
      , mobile: dataModal[indexOfDataModal].mobile
      , ipad: dataModal[indexOfDataModal].ipad
      , desktop: dataModal[indexOfDataModal].desktop});
    // setAmount(dataModal[indexOfDataModal].amount);
    // setDevices(dataModal[indexOfDataModal].devices);
  }
  const handleSubmit = (props) => {
    axios.post(Subscription_Api_Url+'subscription', request).then((resp) => {
      console.log("Add subscription successfully", resp)
      props.handleSubsModalClose();
    })

  }
  return (
    <div>
      <Modal
        open={props.subsModalOpen}
        onClose={props.handleSubsModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="subsContent">
            <div style={{display:'flex',justifyContent:'space-between'}}>
            <div className="subsHeading">Add Subscription</div>
             <div style={{marginTop:'20px',marginLeft:'20px'}}>

          <CloseIcon onClick={props.handleSubsModalClose} className="closeIcon"/>
             </div>
            </div>
    
            <div className="selectFields">
              <div className="Plan">
                <div className="labels">Plan</div>
                <div>
                  <select name="Plan" className="select" onChange={handleOption} id="Plan">
                    <option selected disabled>--Pick a Plan--</option>
                    {
                      dataModal.map((item, index) => {
                        return (
                          <option key={index} value={item.plan + index}>{item.plan}</option>
                        )
                      })
                    }
                  </select>
                </div>
              </div>
             
              <TextField className="mt-2 p-2" id="demo-helper-text-misaligned-no-helper" label="Amount" value={request.amount} />
              {/* <TextField className="mt-2 p-2" id="demo-helper-text-misaligned-no-helper" label="Devices Allowed" value={request.devicesAllowed} /> */}
              <TextField className="mt-2 p-2" id="demo-helper-text-misaligned-no-helper" label="Mobile" value={request.mobile} />
              <TextField className="mt-2 p-2" id="demo-helper-text-misaligned-no-helper" label="Ipad" value={request.ipad} />
              <TextField className="mt-2 p-2" id="demo-helper-text-misaligned-no-helper" label="Desktop" value={request.desktop} />

            </div>
            <div className="btnDiv">
              <button className="subsBtn" onClick={() => handleSubmit(props)}>Add</button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
