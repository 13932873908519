import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import Layout from "../../components/Layout/Layout";
import axios from "axios";
import { Get_All_User_Url } from "../../URL/url";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import { useNavigate } from "react-router-dom";

function User_List(props) {
  // const [deviceDetails, setDeviceDetails] = useState([]);

  const navigate=useNavigate();
  const [userDetails,setUserDetails]=useState([]);
  // useEffect(() => {
  //   axios.get(Device_Api_Url + "device/getAllDevice").then((resp) => {
  //     //  console.log("Node.js 14.17.4 on".indexOf("on"))
  //     const mapData = resp.data.map((items) => {
  //       let myIndex = items.deviceDescription.indexOf("on");
  //       return {
  //         Device_Name: items.deviceName,
  //         Device_IP: items.ipAddress,
  //         Login_Count: items.numberOfLogin,
  //         Device_Details: items.deviceDescription.slice(myIndex + 2),
  //         Device_History: items.deviceDescription.slice(myIndex + 2),
  //         Device_Location: items.deviceDescription.slice(myIndex + 2),
  //         Action: (
  //           <>
  //             <EditIcon stroke="blue" />
  //             <BlockIcon stroke="red" />
  //           </>
  //         ),
  //       };
  //     });
  //     //  console.log(mapData,"map")
  //     setDeviceDetails(mapData);
  //   });
  // }, []);

  useEffect(() => {
    axios.get(Get_All_User_Url + "/getAllUser").then((resp) => {
      const mapData = resp.data.map((items) => {
        // let myIndex = items.deviceDescription.indexOf("on");
        return {
            User_Name: items?.email,
            User_Id: items?._id,
            Plan: items?.subscriptionPlan,
        };
      });
      //  console.log(mapData,"map")
      setUserDetails(mapData);
    });
  }, []);

  // const columns = [
  //   {
  //     name: "Device_Name",
  //     label: "Device Name",
  //     options: {
  //       filter: true,
  //       sort: true,
  //     },
  //   },
  //   {
  //     name: "Device_Details",
  //     label: "Device Details",
  //     options: {
  //       filter: true,
  //       sort: false,
  //     },
  //   },
  //   {
  //     name: "Device_IP",
  //     label: "Device IP",
  //     options: {
  //       filter: true,
  //       sort: false,
  //     },
  //   },
  //   {
  //     name: "Device_History",
  //     label: "Device History",
  //     options: {
  //       filter: true,
  //       sort: false,
  //     },
  //   },
  //   {
  //     name: "Device_Location",
  //     label: "Device Location",
  //     options: {
  //       filter: true,
  //       sort: false,
  //     },
  //   },
  //   {
  //     name: "Login_Count",
  //     label: "Login Count",
  //     options: {
  //       filter: true,
  //       sort: false,
  //     },
  //   },
  //   {
  //     name: "Action",
  //     label: "Action",
  //   },
  // ];
  // const data = [
  //     { Device_Name: "Joe James", Device_Details: "Test Corp", Device_IP: "Yonkers", Device_History: "NY", Device_Location: "NY", Login_Count: "1" },
  //     { Device_Name: "John Walsh", Device_Details: "Test Corp", Device_IP: "Hartford", Device_History: "CT", Device_Location: "NY", Login_Count: "1" },
  //     { Device_Name: "Bob Herm", Device_Details: "Test Corp", Device_IP: "Tampa", Device_History: "FL", Device_Location: "NY", Login_Count: "1" },
  //     { Device_Name: "James Houston", Device_Details: "Test Corp", Device_IP: "Dallas", Device_History: "TX", Device_Location: "NY", Login_Count: "1" },
  // ];

 

  const userColumns = [
    {
      name: "User_Name",
      label: "User Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "User_Id",
      label: "User Id",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Plan",
      label: "Plan",
      options: {
        filter: true,
        sort: false,
      },
    }
  ];

  const handleRowClick = (rowData, rowMeta) => {
    console.log(rowData[1]);
    navigate(`/admin/user_list/${rowData[1]}`);
    // /admin/user_list/:id
};
const options = {
  filterType: "checkbox",
  onRowClick: handleRowClick,
};

  return (
    <Layout role="admin">
      {/* <MUIDataTable
        title={"Device List"}
        data={deviceDetails}
        columns={columns}
        options={options}
      /> */}
      <MUIDataTable title={"User List"} 
        data={userDetails}
        columns={userColumns}
        options={options}
      />
    </Layout>
  );
}

export default User_List;
