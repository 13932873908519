import Devices from "../pages/User/Devices.component";
import DeviceHistory from "../pages/User/History.component";
import NotFound from "../components/NotFound.component";
import Sidebar from "../pages/Sidebar/Sidebar.component";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Signup from "../pages/Signup/Signup.component";
// import { Payment } from "@mui/icons-material";
import Login from "../pages/Login/Login.component";
import Payment from "../pages/Payment/Payment.component";
import Subscription from "../pages/Subscription/Subscription.component";
import Layout from "../components/Layout/Layout";
import DeviceList from "../pages/Admin/Device_list.component";
import Subscription_list from '../pages/Admin/Subscription_list.component'
import User_List from '../pages/Admin/User_list.component';

function Routess() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Signup />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/login" element={<Login />} />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/dashboard" exact element={<Sidebar />} />
        <Route path="/user/devices" element={<Devices/>} />
        <Route path="/user/history" element={<DeviceHistory />} />
        <Route path="/admin/user_list" element={<User_List />} />
        <Route path="/admin/user_list/:id" element={<DeviceList />} />
        <Route path="/admin/Subscription_List" element={<Subscription_list />} />
        <Route element={<NotFound />} />
      </Routes> 
    </BrowserRouter>
  );
}
export default Routess;
