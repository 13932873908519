import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import Layout from "../../components/Layout/Layout";
import axios from "axios";
import { Subscription_Api_Url } from "../../URL/url";



function Subscription_list(props) {

    const [subscriptionList, setSubscriptionList] = useState([]);
    
    useEffect(()=>{
        axios.get(Subscription_Api_Url+'subscription').then((resp)=>{
            console.log(resp.data, "Get All Subscription")
            const mapData = resp.data.data.map(items=>{
                return {
                    Subscription_type: items?.subscriptionPlan,
                    Mobile: items?.mobile,
                    IPad: items?.ipad,
                    Desktop: items?.desktop,
                    Amount: items?.amount
                }
             })
             console.log(mapData,"map")
             setSubscriptionList(mapData)
        })
     
    },[])
    const columns = [
        {
            name: "Subscription_type",
            label: "Subscription Type",
            options: {
                filter: true,
                headerName: 'number',
                sort: false,
            }
        },
         {
            name: "Mobile",
            label: "Mobile",
            options: {
                filter: true,
                sort: false,
            }
        },{
            name: "IPad",
            label: "IPad",
            options: {
                filter: true,
                sort: false,
            }
        },{
            name: "Desktop",
            label: "Desktop",
            options: {
                filter: true,
                sort: false,
            }
        }
        ,{
            name: "Amount",
            label: "Amount",
            options: {
                filter: true,
                sort: false,
            }
        }
    ];
    // const data = [
    //     { Subscription_type: "Joe James", Total_Allowed_Devices: "Test Corp"/* , Mobile: "Yonkers", Tab: "NY", Desktop: "NY" */,Amount:600 },
    //     { Subscription_type: "John Walsh", Total_Allowed_Devices: "Test Corp"/* , Mobile: "Hartford", Tab: "CT", Desktop: "NY" */,Amount:600 },
    //     { Subscription_type: "Bob Herm", Total_Allowed_Devices: "Test Corp"/* , Mobile: "Tampa", Tab: "FL", Desktop: "NY" */,Amount:600 },
    //     { Subscription_type: "James Houston", Total_Allowed_Devices: "Test Corp"/* , Mobile: "Dallas", Tab: "TX", Desktop: "NY" */,Amount:600 },
    // ];

    const options = {
        filterType: 'checkbox',
    }
    return (
        <Layout role="admin">

            <MUIDataTable
                title={"Subscription List"}
                data={subscriptionList}
                columns={columns}
                options={options}
            />
        </Layout>
    );
}

export default Subscription_list;
