import React ,{useState} from 'react'
import { useEffect } from 'react';
import axios from 'axios';
import { Get_User_Device_List, User_Api_Url } from '../../URL/url';
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import MUIDataTable from "mui-datatables";
import Layout from "../../components/Layout/Layout";
import { useParams } from 'react-router-dom';
// import axios from 'axios';

function DeviceList() {
  const [deviceDetails, setDeviceDetails] = useState([]);
  let { id } = useParams();
  console.log(id,"sdfghjkl");
  const handleBlock=()=>{
    axios.patch(User_Api_Url + `/block-user`,{userId:id}).then((resp)=>{
      console.log(resp,"here is my block response");
    })
  }
  useEffect(() => {
    axios.get(Get_User_Device_List + `device/deviceList/${id}`).then((resp) => {
      //  console.log("Node.js 14.17.4 on".indexOf("on"))
      const mapData = resp.data.map((items) => {
        let myIndex = items.deviceDescription.indexOf("on");
        let mydevice;
        if(items.isMobile){
          mydevice="Mobile";
        }
        else if(items.isDesktop){
          mydevice="Desktop";
        }
        else if(items.isIpad){
          mydevice="Ipad";
        }
        return {
          Device_Name: mydevice,
          Device_IP: items.ipAddress,
          Login_Count: items.numberOfLogin,
          Device_Details: items.deviceDescription.slice(myIndex + 2),
          Device_History: items.deviceDescription.slice(myIndex + 2),
          Device_Location: items.deviceDescription.slice(myIndex + 2),
          Action: (
            <>
              {/* <EditIcon stroke="blue" /> */}
              <BlockIcon onClick={handleBlock} stroke="red" />
            </>
          ),
        };
      });
      //  console.log(mapData,"map")
      setDeviceDetails(mapData);
    });
  }, []);
  const columns = [
    {
      name: "Device_Name",
      label: "Device Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Device_Details",
      label: "Device Details",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Device_IP",
      label: "Device IP",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Device_History",
      label: "Device History",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Device_Location",
      label: "Device Location",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Login_Count",
      label: "Login Count",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Action",
      label: "Action",
    },
  ];

  const handleRowClick = (rowData, rowMeta) => {
    console.log(rowData);
};
const options = {
  filterType: "checkbox"
};

  return (
    <Layout role="admin">
      <MUIDataTable
        title={"Device List"}
        data={deviceDetails}
        columns={columns}
        options={options}
      />
    </Layout>
  )
}

export default DeviceList;