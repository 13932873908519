import "./Subscription.styles.css";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { Subscription_Api_Url } from "../../URL/url";
import { useLocation } from "react-router-dom";

const Subscription = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.state,"location");
  const [userSubscriptionList, setUserSubscriptionList] = useState([])
  useEffect(() => {
    const userId = localStorage.getItem('userId')
    axios.get(Subscription_Api_Url + 'subscription').then((resp) => {
      console.log(resp.data.data, "Get All Subscription")
      setUserSubscriptionList(resp.data.data)
    })

  }, [])
  const handleSubscription = (id)=>{
    const userId = localStorage.getItem('userId')
    const data = {
      userId: userId,
      subscriptionId: id
    }
    console.log("api tak aaya");
    axios.post(Subscription_Api_Url + 'subscription/add', data).then((resp) => {
      console.log(resp.data.data, "Get All Subscription")
      setUserSubscriptionList(resp.data.data)
      navigate('/payment',{state:location.state});
    })
  }
  return (
    <div>
      <div
        className=""
        style={{ paddingTop: "40px", height: "100vh" }}
      >
        <div className="row">
          {/* <!-- Purple Table --> */}
          {userSubscriptionList && userSubscriptionList.map((items) => {
           
            return(
            

              <div className="col-md-4">
              <div className="pricing-table purple">
                {/* <!-- Table Head --> */}
                {/* <div className="pricing-label">Fixed Price</div> */}
                <h2>{items.subscriptionPlan}</h2>
                <h5>Made for starters</h5>
                {/* <!-- Features --> */}
                <div className="pricing-features">
                  <div className="feature">
                    <div className="deviceTitle">
                    Devices Allowed
                    </div>
                    <div className="paraName">
                      <span className="titleName">Mobile</span>
                      <span className="titleValue">{items.mobile}</span>
                    </div>
                    <div className="paraName">
                    <span className="titleName">Desktop</span>
                      <span className="titleValue">{items.desktop}</span>
                    </div>
                    <div className="paraName">
                    <span className="titleName">Tab</span>
                      <span className="titleValue">{items.ipad}</span>
                    </div>
                  </div>
                  
                </div>
                {/* <!-- Price --> */}
                <div className="price-tag">
                  <span className="symbol"></span>
                  <span className="amount">{items.amount}</span>
                  <span className="after">/month</span>
                </div>
                {/* <!-- Button --> */}
                <span onClick={()=>{handleSubscription(items._id)}} className="price-button">
                  Subscribe
                </span>
              </div>
            </div>
            )
            
          })}
            {/* <div className="col-md-4">
              <div className="pricing-table purple">
             <div className="pricing-label">Fixed Price</div>
                <h2>Basic 2022</h2>
                <h5>Made for starters</h5>
            
                <div className="pricing-features">
                  <div className="feature">
                    Bandwith<span>50 GB</span>
                  </div>
                  <div className="feature">
                    Add-On Domains<span>10</span>
                  </div>
                  <div className="feature">
                    SSD Storage<span>250 GB</span>
                  </div>
                  <div className="feature">
                    Mail Adresses<span>25</span>
                  </div>
                  <div className="feature">
                    Support<span>Only Mail</span>
                  </div>
                </div>
              
                <div className="price-tag">
                  <span className="symbol">$</span>
                  <span className="amount">7.99</span>
                  <span className="after">/month</span>
                </div>
             
                <Link to="/payment" className="price-button" href="#">
                  Get Started
                </Link>
              </div>
            </div>

        
          <div className="col-md-4">
            <div className="pricing-table turquoise">
            
              <div className="pricing-label">Fixed Price</div>
              <h2>Extended 2022</h2>
              <h5>Made for experienced users</h5>
            
              <div className="pricing-features">
                <div className="feature">
                  Bandwith<span>150 GB</span>
                </div>
                <div className="feature">
                  Add-On Domains<span>25</span>
                </div>
                <div className="feature">
                  SSD Storage<span>500 GB</span>
                </div>
                <div className="feature">
                  Mail Adresses<span>50</span>
                </div>
                <div className="feature">
                  Support<span>Mail/Phone</span>
                </div>
              </div>
           
              <div className="price-tag">
                <span className="symbol">$</span>
                <span className="amount">9.99</span>
                <span className="after">/month</span>
              </div>
           
              <Link to="/payment" className="price-button" href="#">
                Get Started
              </Link>
            </div>
          </div>
         
          <div className="col-md-4">
            <div className="pricing-table red">
            
              <div className="pricing-label">Fixed Price</div>
              <h2>Premium 2022</h2>
              <h5>Made for professionals/agencies</h5>
           
              <div className="pricing-features">
                <div className="feature">
                  Bandwith<span>250 GB</span>
                </div>
                <div className="feature">
                  Add-On Domains<span>50</span>
                </div>
                <div className="feature">
                  SSD Storage<span>1 TB</span>
                </div>
                <div className="feature">
                  Mail Adresses<span>75</span>
                </div>
                <div className="feature">
                  Support<span>7/24</span>
                </div>
              </div>
           
              <div className="price-tag">
                <span className="symbol">$</span>
                <span className="amount">12.99</span>
                <span className="after">/month</span>
              </div>
          
              <Link to="/payment" className="price-button" href="#">
                Get Started
              </Link>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Subscription;
