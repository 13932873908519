import "./Payment.styles.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { User_Api_Url } from "../../URL/url";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const Payment = () => {
  const location = useLocation();
  const navigate=useNavigate()
  const handleLogin=()=>{
    axios.post(`${User_Api_Url}/signin`,location.state).then((resp)=>{
      if(resp.data?.data?.isEmailVerified ){
        localStorage.setItem('userId',resp.data?.data._id)
        toast.success("Login Succesfully", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        localStorage.setItem('isLoggedIn',resp.data?.data?.isLoggedIn)
        if(resp.data?.data?.role.role === "admin"){
          navigate('/admin/User_list');
        }else{
          navigate('/user/devices');
        }
      }
    })
  }
  return (
    <div>
      <div className="container">
        <div className="upperCard">
          <h1>Purchase complete</h1>
          <p>
            Order placed on <br /> Monday, August 3, 2022
          </p>
        </div>
        <div className="lowerCard">
          <ul>
            <li className="rHeading">
              <span>Premium 2022</span> <span>Total</span>
            </li>
            <li className="rDescription">
              The Premium 2022 combines the spirit...
              <span>
                $<b>12</b>.99
              </span>
            </li>
          </ul>
        </div>
        <button onClick={handleLogin} id="button">Continue</button>
      </div>
    </div>
  );
};

export default Payment;
