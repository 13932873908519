const SidebarItems = [
  {
    name: "Devices",
    route: "/user/devices",
    role: "user",
    modal:false
  },
  {
    name: "History",
    route: "/user/history",
    role: "user",
    modal:false
  },
  {
    name: "User List",
    route: "/admin/user_list",
    role: "admin",
    modal:false
  },
  {
    name: "Subscription List",
    route: "/admin/Subscription_List",
    role: "admin",
    modal:false
  },
  {
    name: "Add Subscription",
    route: "",
    role: "admin",
    modal:true
  },
];

export default SidebarItems;
