import "./Sidebar.styles.css";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import {SidebarUserItems,SidebarAdminItems} from "./SidebarItems";
import SidebarItems from "./SidebarItems";
import { Link, useLocation } from "react-router-dom";
import AddSubsModal from "../Admin/AddSubsModal/AddSubsModal";
import { useNavigate } from "react-router-dom";
import { User_Api_Url } from "../../URL/url";
import axios from "axios";
import TableRowsIcon from '@mui/icons-material/TableRows';

function Sidebar(props, { defaultActive }) {
  let navigate=useNavigate();
  const location = useLocation();
  const lastActiveIndexString = localStorage.getItem("lastActiveIndex");
  const lastActiveIndex = Number(lastActiveIndexString);
  const [sidebarCheck,setSidebarCheck]=useState(false);

  const [subsModalOpen, setSubsModalOpen] = useState(false);

  const [activeIndex, setActiveIndex] = useState(
    lastActiveIndex || defaultActive
  );

  function changeActiveIndex(newIndex) {
    localStorage.setItem("lastActiveIndex", newIndex);
    setActiveIndex(newIndex);
  }

  const handleLogout=()=>{
    let myToken=localStorage.getItem('token');
    console.log(myToken,"here is my token");
    localStorage.clear();
    axios.post(User_Api_Url+'/signout',{token:myToken}).then((resp)=>{
      console.log(resp,"here is my response of logout");
    navigate('/login');
    })
  }

  function getPath(path) {
    if (path.charAt(0) !== "/") {
      return "/" + path;
    }
    return path;
  }

  const handleSubsModalClose = () => setSubsModalOpen(false);
  const handleSubsModalOpen =()=> setSubsModalOpen(true);


  useEffect(() => {
    const activeItem = SidebarItems.findIndex(
      (item) => getPath(item.route) === getPath(location.pathname)
    );
    changeActiveIndex(activeItem);
  }, [location]);

  const handleSidebar=()=>{
    setSidebarCheck(!sidebarCheck);
  }

  return (
    <>
    <TableRowsIcon className={sidebarCheck ? "gridIconColor" : "gridIcon"}onClick={handleSidebar}/>
      <SidebarParent className={sidebarCheck ? "sidebar" : "showSideBar"}>
        <div style={{ position: "fixed",
      backgroundColor:"rgb(26 29 44)" }}>
          <img
            src="https://icdn.video/sites/all/themes/ctv_theme/logo.png"
            alt=""
            style={{ width: "100%", height: "auto" }}
          />
          {SidebarItems.map((item, index) => {
            if(props.role === item.role ){
              return (
                <>
              <Link key={index} to={item.route}>
              {
                item.modal ? <>
                <SidebarItem onClick={
                  item.modal?
                  handleSubsModalOpen : null
                  } key={item.name} active={index === activeIndex}>
                  <p>{item.name}</p>
                </SidebarItem>
                <AddSubsModal
          subsModalOpen={subsModalOpen} 
          handleSubsModalClose={handleSubsModalClose}
        />
                </>
                :
                <SidebarItem key={item.name} active={index === activeIndex}>
                  <p>{item.name}</p>
                </SidebarItem>
              }
              </Link>
              </>
            );
            }else {
              <></>
            }
          })}
          <div onClick={handleLogout}>
              <SidebarItem>
                  <p>Logout</p>
                </SidebarItem>
              </div>
        </div>
        <div className="behind-the-scenes" />
      </SidebarParent>
    </>
  );
}

export default Sidebar;

const SidebarParent = styled.div`
  background: rgb(217 220 239);

  a {
    text-decoration: none;
  }

  & > div {
    width: 250px;
    height: 100vh;
  }

  .behind-the-scenes {
    width: 250px;
  }
`;

const SidebarItem = styled.div`
  margin-top: 10px;
  padding: 10px 18px;
  transition: all 0.25s ease-in-out;
  background: ${(props) => (props.active ? "#6c96fb" : "")};

  border-radius: 4px;
  p {
    color: white;
    font-weight: bold;
    text-decoration: none;
    padding-top: 10px;
  }

  &:hover {
    cursor: pointer;
  }

  &:hover:not(:first-child) {
    background: #c34a36;
  }
`;
